import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

export default new Router({
    mode: 'hash',
    routes: [
        {
            path: "/",
            redirect: "/dashboard"
        },
        {
            path: "/",
            component: () => import("../components/Home.vue"),
            meta: {title: "home"},
            children: [
                {
                    path: "dashboard",
                    component: () => import("../pages/dashboard"),
                    meta: {title: "工作台", keepAlive: false}
                },
                //账户管理  -----   密码管理
                {
                    path: 'passwordManagement',
                    component: () => import('../pages/accountManagement/passwordManagement'),
                    meta: {title: '密码管理', keepAlive: false}
                },
				//账户管理  -----   银行卡
				{
				    path: 'bankManagement',
				    component: () => import('../pages/accountManagement/bankManagement'),
				    meta: {title: '银行卡', keepAlive: false}
				},
				//账户管理  -----   绑定谷歌
				{
				    path: 'googleValidator',
				    component: () => import('../pages/accountManagement/googleValidator'),
				    meta: {title: '绑定谷歌', keepAlive: false}
				},
				//财务管理  -----   入金申请
				{
				    path: 'epositApplication',
				    component: () => import('../pages/caiwuManagement/epositApplication'),
				    meta: {title: '入金申请', keepAlive: false}
				},
				//财务管理  -----   手动代付
				{
				    path: 'manualPayment',
				    component: () => import('../pages/caiwuManagement/manualPayment'),
				    meta: {title: '手动代付', keepAlive: false}
				},
				//财务管理  -----   收银台
				{
				    path: 'checkstand',
				    component: () => import('../pages/caiwuManagement/checkstand'),
				    meta: {title: '收银台', keepAlive: false}
				},
				//财务管理  -----   交易统计
				{
				    path: 'transactionStatistics',
				    component: () => import('../pages/caiwuManagement/transactionStatistics'),
				    meta: {title: '交易统计', keepAlive: false}
				},
				//财务管理  -----   账变记录
				{
				    path: 'accountChangeRecord',
				    component: () => import('../pages/caiwuManagement/accountChangeRecord'),
				    meta: {title: '账变记录', keepAlive: false}
				},
				//订单管理  -----   代收订单
				{
				    path: 'daishouOrder',
				    component: () => import('../pages/orderManagement/daishouOrder'),
				    meta: {title: '代收订单', keepAlive: false}
				},
				//订单管理  -----   代付订单
				{
				    path: 'daifuOrder',
				    component: () => import('../pages/orderManagement/daifuOrder'),
				    meta: {title: '代付订单', keepAlive: false}
				},
				//结算管理  -----   结算申请
				{
				    path: 'settlementApplication',
				    component: () => import('../pages/jiesuanManagement/settlementApplication'),
				    meta: {title: '结算申请', keepAlive: false}
				},
				//结算管理  -----   结算记录
				{
				    path: 'settlementRecord',
				    component: () => import('../pages/jiesuanManagement/settlementRecord'),
				    meta: {title: '结算记录', keepAlive: false}
				},
				//代理管理  -----   商户列表
				{
					path: 'shlist',
					component: () => import('../pages/agentManagement/shlist'),
					meta: {title: '商户列表', keepAlive: false}
				},
				//代理管理  -----   交易记录
				{
					path: 'agentRecord',
					component: () => import('../pages/agentManagement/agentRecord'),
					meta: {title: '交易记录', keepAlive: false}
				},
				//代理管理  -----   设置费率
				{
					path: 'setFl',
					component: () => import('../pages/agentManagement/setFl'),
					meta: {title: '设置费率', keepAlive: false}
				},
				//API管理  -----   代收通道
				{
				    path: 'channel',
				    component: () => import('../pages/apiManagement/channel'),
				    meta: {title: '代收通道', keepAlive: false}
				},
				//API管理  -----   代付费率
				{
				    path: 'rate',
				    component: () => import('../pages/apiManagement/rate'),
				    meta: {title: '代付费率', keepAlive: false}
				},
				//API管理  -----   API信息
				{
				 	path: 'message',
				 	component: () => import('../pages/apiManagement/message'),
				 	meta: {title: 'API信息', keepAlive: false}
				},
            ]
        },
        {
            path: '/login',
            component: () => import('../pages/login'),
            meta: {title: '登录'}
        },
        {
            path: "*",
            redirect: "/dashboard"
        }
    ]
})

import Vue from 'vue'
import Vuex from 'vuex'
import loginRoot from './loginRoot'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        isShowLoading: false, // 全局 loading
        subjectColor:'#FFFFFF', //主题颜色
        tagColor:'#51CBCD',
        goodsInfo:{},
        uploadingUrl:'', //上传域名
        ossUrl:'', //静态资源域名
        routerArr:[],
        empId:'',//部门id
        buttonFlag:true,
        jiaobiaoNum:0,
        logoImg:'',
    },
    getters: {
        data(state) {
            return state.buttonFlag
        },
        newData(state) {
            console.log(state)
            return state.jiaobiaoNum
        },
        newLogo(state){
            return state.logoImg
        },
    },
    mutations: {
        SET_GOODSINFO(state, data) {
            state.goodsInfo = data;
        },
        setRouterArr(state,data){
            state.routerArr = data
        },
        setEmpId(state,data){
            state.empId = data
        },
        setButtonFlag(state,data){
            state.buttonFlag = data
        },
        setJiaobiao(state,num){
            console.log(num)
            state.jiaobiaoNum = num
        },
        setLogo(state,num){
            state.logoImg = num
        },
    },

    modules:{
        loginRoot
    }
})

export default store

<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  data(){
    return{
    }
  },
  created(){

  },
  mounted() {
    
  },
  methods:{
  }
};
</script>

<style>
  .ql-editor {
    white-space: normal !important;
  }

  .ql-container {
    white-space: pre-wrap !important;
  }
</style>
